.ovSlide {
    background-color: initial !important;
}

.window-cleaning {
    background-image:url("../images/photo-window.jpg");
}

.gutter-cleaning {
    background-image:url("../images/photo-gutter.jpg");
}

.weatherboard-cleaning {
    background-image:url("../images/photo-weatherboard.jpg");
}

.conservatory-cleaning {
    background-image:url("../images/photo-conservatory.jpg");
}

blockquote:before{
    background: url('../images/openquote.png');
}

blockquote:after{
    background: url('../images/closequote.png');
}


.external-logos {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: center;


    .small-gen-logos {
        margin-top: 5px;
    }
}

.small-social-logos,
.small-gen-logos {

    display: flex;
    flex-direction: column;
    row-gap: 20px;

    li {
        background-color: initial !important;
        height: 55px;
        width: 60px;
        margin: 0;
        padding: 0 !important;

        img {
            margin: initial;
            width: 100%;
        }
    }

    li.qfa {
        margin-top: 5px;
    }

    li.reach {
        margin-top: 2px;
        margin-left: 6px;
    }


    &.horizontal {
        flex-direction: row;
    }

    &.small {
        column-gap: 10px;

        li {
            width: 45px;
        }
    }
}

@media(width <= 800px) {

    .small-social-logos,
    .small-gen-logos {

        &.horizontal {
            justify-content: center;
        }

    }

}
