/* Reset Styling */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}
/* remember to define focus styles! */
:focus {
	outline: 0;
}
body {
	line-height: 1;
	color: black;
	background: white;
}

	ol, ul {
	list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: separate;
	border-spacing: 0;
}
caption, th, td {
	text-align: left;
	font-weight: normal;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}
blockquote, q {
	quotes: "" "";
}

/* End of Reset Styling */

/* Default Styling */
.rowFlex, .homeColumns, .log, .colFlex, .invoices tr, .rightSmall form .inline, .beforeAfter, #content #checkatradeScores ul li, .pEstimator, .sFlex, .sFlex .sFlex div, .pEstimator .field, .priceFlex, .servicePrices tr, .contactInfo .field, .singlePay, .log .field div, #vidSpacing, .singlePay .field div, .socialLogos, .footerInfo {
	display: inline-block;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
    display: flex;
}

.rowFlex, .homeColumns, .invoices tr, .rightSmall form .inline, .beforeAfter, #content #checkatradeScores ul li, .pEstimator, .sFlex, .sFlex .sFlex div, .log .field div, .priceFlex, .servicePrices tr, .contactInfo .field, #vidSpacing, .singlePay .field div, .socialLogos, .footerInfo {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.pEstimator .field, .singlePay, .colFlex, .log {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.rowFlex, .homeColumns, .invoices tr, .beforeAfter, .pEstimator, .servicePrices tr, .contactInfo .field, .log .field div, .footerInfo {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.sFlex, .sFlex .sFlex div, .colFlex, .log, .rightSmall form .inline, .pEstimator .field, #content #checkatradeScores ul li, .priceFlex, .singlePay, #vidSpacing, .singlePay .field div, .socialLogos {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.colFlex {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}

.rowFlex, .homeColumns, .log, .invoices tr, .beforeAfter, #content #checkatradeScores ul li, .pEstimator, .sFlex, .sFlex .sFlex div, .pEstimator .field, .priceFlex, .servicePrices tr, .contactInfo .field, .singlePay, .log .field div, #vidSpacing, .singlePay .field div, .socialLogos, .footerInfo {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
}

.rightSmall form .inline , .sAround {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
}

html,
body {
	color:#666666;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size:16px;
	text-align:center;
}
body {
	max-width: 100%;
}

.no-mobile {
	display:none;
}

p {
	line-height:22px;
	margin:0 0 22px 0;
}

a {
	color:#06A2D7;
	font-weight:700;
	text-decoration:none;
}

a:hover{
	text-decoration: underline;
}

a.skip-link:hover,
.left a:hover,
footer a:hover {
	color:#5F2282;
}

h1,
h2,
h3 {
	font-family: fjallaOne, Impact, Arial, Helvetica, sans-serif;
	text-transform: uppercase;
}

h2 {
	color: #5F2282;
    font-size: 32px;
    width: 100%;
    text-align: center;
}

h3 {
	font-size:24px;
}

/* HEADER STYLING */

a.skip-link {
	background:#06A2D7;
	color:#FFFFFF;
	display:block;
	padding:11px 0;
	margin-bottom:30px;
	width:100%;
}

header .ezy-logo {
	max-width:200px;
	width:100%;
}

.ezy-clean-tp-mini {
	width: 300px;
	max-width: 200px;
    position: relative;
    transition: opacity 1s ease;
    float: right;
}

header .tagline {
	color:#5F2282;
	font-style:italic;
	line-height:16px;
	margin-bottom:26px;
	text-transform:lowercase;
}

header .phone {
	font-size:36px;
}

/* NAV STYLING */
#cssmenu {
  background:#06A2D7;
  margin: 0;
  width: auto;
  padding: 0;
  line-height: 1;
  display: block;
  position: relative;
  font-size:16px;
  font-weight:700;
}
#cssmenu ul {
  background:#06A2D7;
  list-style: none;
  margin: auto;
  padding: 0;
  display: block;
}
#cssmenu ul:after,
#cssmenu:after {
  content: " ";
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  visibility: hidden;
}
#cssmenu ul li {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}
#cssmenu ul li a {
  text-decoration: none;
  display: block;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu > ul > li {
  float: left;
}
#cssmenu.align-center > ul > li {
  float: none;
  display: inline-block;
}
#cssmenu.align-center > ul {
  text-align: center;
}
#cssmenu.align-center ul ul {
  text-align: left;
}
#cssmenu.align-right > ul {
  float: right;
}
#cssmenu.align-right > ul > li:hover > ul {
  left: auto;
  right: 0;
}
#cssmenu.align-right ul ul li:hover > ul {
  right: 100%;
  left: auto;
}
#cssmenu.align-right ul ul li a {
  text-align: right;
}
#cssmenu > ul > li.has-sub > a {
  padding-right: 40px;
}
#cssmenu > ul > li.has-sub > a:after {
  display: block;
  content: "";
  background: #000000;
  height: 12px;
  width: 12px;
  position: absolute;
  border-radius: 13px;
  right: 14px;
  top: 16px;
}
#cssmenu > ul > li > a {
  color: #FFFFFF;
  padding: 15px 0px;
  z-index: 2;
  position: relative;
}
#cssmenu > ul > li:hover:after,
#cssmenu > ul > li.active:after {
  height: 100%;
}
#cssmenu a.active,
#cssmenu > ul > li:hover > a,
#cssmenu > ul > li.active > a {
  color:#5F2282;
}
#cssmenu > ul > li:hover > ul {
  left: 0;
}
#cssmenu > ul > li:hover > ul > li {
  max-height: 72px;
  position: relative;
}
#cssmenu #menu-button {
  display: none;
}
#cssmenu > ul > li > a {
  display: block;
}
#cssmenu > ul > li {
  width: auto;
}

.homeColumns {
	align-items: flex-start;
}

/* Price Estimator Styling */
.pEstimator{
	margin: 20px 0;
}

.sFlex{
	width: 100%;
	margin: 10px 0 20px 0;
	padding-bottom: 20px;
	border-bottom: 1px dashed #06a2d7;
}

.sFlex .sFlex div{
	width: 100%;
}

.sSides{
	width: 50%;
}

.sFlex p{
	width: 49%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.sFlex .specialF{
	width: 49%;
}

.sFlex .specialF p{
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.pEstimator .field{
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	color: #FFFFFF;
	display: block;
	padding: 20px 25px;
	width: calc(40% - 50px);
	background: #FFCC00;
	text-shadow:1px 1px 1px rgba(0,0,0,0.5);
	align-self: center;
}

.pEstimator .specialF .spPad{
	height: 70px;
    margin-bottom: 30px;
    margin-top: 0;
}

.pEstimator .specialF .spPad:last-child{
	margin-bottom: 0;
}

.pEstimator .sSides .field{
	width: calc(80% - 50px);
	margin-top: 10px;
	margin-left: 20%;
}

.pEstimator .field .sFlex{
	border: 0;
	margin: 0;
	padding-top: 20px;
	padding-bottom: 0;
}

.pEstimator .field .sFlex label{
	font-weight: 700;
	text-shadow: none;
	color: #4c1b68;
}

.pEstimator .field .sFlex:first-child{
	padding-top: 0;
}

.pEstimator .sSides .field:first-child{
	margin-top: 0px;
}

.pEstimator .field label{
	width: 60%;
	line-height: 18px;
	font-weight: 700;
	text-shadow: none;
	color: #4c1b68;
}

.pEstimator .field input[type="number"]{
	width: 60px;
	background-color: #Ffffff;
	color: #000000;
	border: 0;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	text-align: center;
	-moz-appearance: textfield;
	-webkit-appearance: none;
	margin: 0;
}

.pEstimator .field input[type="checkbox"]{
	max-width: 15px;
	max-height: 15px;
}

#priceEstimatorForm_conservatory, #priceEstimatorForm_gutterClearing, #priceEstimatorForm_fasciaCleaned, #priceEstimatorForm_conservatoryRoof {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
}

.pEstimator input[type="checkbox"] + label {
    display: none;
}

.pEstimator .field #conservatoryRoof{
	width: 60px;
}

.pEstimator .field select{
	width: 25%;
	background-color: #Ffffff;
	color: #000000;
	border: 0;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	text-align: center;
	-moz-appearance: textfield;
	-webkit-appearance: none;
	margin: 0;
}

.pEstimator .field .bigSel{
	width: 35%;
}

.pEstimator #estimateButton div{
	width: 40%;
}

.pEstimator button, .userSitemap .button{
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: 0;
	color: #FFFFFF;
	display: block;
	margin-bottom: 20px;
	padding: 12px 25px;
	text-align: center;
	width: 100%;
	background: #5F2282;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	cursor: pointer;
}

.pEstimator button:hover, .userSitemap .button:hover{
	background: #4c1b68;
}

.servicePrices{
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.servicePrices tr{
	margin-bottom: 5px;
	border-bottom: 1px dashed #06A2D7;
}

.servicePrices tr:first-child td{
	font-weight: 700;
}

.servicePrices td{
	width: 30%;
	text-align: right;
	padding: 10px 0;
}

.servicePrices td:first-child{
	width: 60%;
	text-align: left;
}

.servicePrices td:last-child{
	width: 30%;
}

.contactInfo{
	width: calc(40% - 50px);
	margin-top: 20px;
	margin-bottom: 20px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	color: #FFFFFF;
	display: block;
	margin-bottom: 20px;
	padding: 16px 25px 16px 25px;
	background: #FFCC00;
	text-shadow:1px 1px 1px rgba(0,0,0,0.5);
}

.contactInfo .field{
	width: 100%;
	margin-bottom: 10px;
}

.contactInfo .field label{
	display: block;
	width: 100%;
	text-align: center;
	line-height: 18px;
	margin-bottom: 5px;
	font-weight: 700;
	text-shadow: none;
	color: #4c1b68;
}

.contactInfo .field div{
	width: 100%;
}

.contactInfo .field input, .contactInfo .field textarea{
	width: 100%;
	background-color: #Ffffff;
	color: #000000;
	border: 0;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	margin: 0;
}

.contactInfo .field textarea{
	min-width: 100%;
	max-width: 100%;
	min-height: 50px;
	max-height: 200px;
	background-color: #Ffffff;
	color: #000000;
	border: 0;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	margin: 0;
}

.contactInfo button{
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: 0;
	color: #FFFFFF;
	display: block;
	margin-bottom: 20px;
	padding: 16px 25px 16px 25px;
	text-align: center;
	width: 100%;
	background: #5F2282;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	cursor: pointer;
}

.contactInfo button:hover{
	background: #4c1b68;
}

a img {
	width: 100%;
}

/* New Cleaners Styling */

.cleaners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

.cleaner {
    overflow: hidden;
    height: 300px;
    margin-bottom: 20px;
    background-color: #e3e3e3;
}

.cleaner img {
  opacity: 1;
  display: block;
  max-height: 300px;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  width: auto;
  margin: auto;
}

.cleanerBio {
  transition: .5s ease;
  opacity: 0;
  text-align: center;
  width: 250px;
  transform: translateY(-120px);
}

.cleanerBio p {
	font-weight: bold;
	color: black;
}

.cleaner:hover img {
  opacity: 0.3;
}

.cleaner:hover .cleanerBio {
  opacity: 1;
}

header .elfsight-app-d5f24f79-7489-4449-8eb6-adec60c6febd {
    width: 300px;
    position: absolute;
    right: -30px;
    top: 30px;
}

@media all and (max-width: 720px),
only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 720px),
only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 720px),
only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 720px),
only screen and (min-device-pixel-ratio: 2) and (max-width: 720px),
only screen and (min-resolution: 192dpi) and (max-width: 720px),
only screen and (min-resolution: 2dppx) and (max-width: 720px) {
  #cssmenu > ul {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.35s;
    -moz-transition: max-height 0.35s;
    -ms-transition: max-height 0.35s;
    -o-transition: max-height 0.35s;
    transition: max-height 0.35s;
  }
  #cssmenu > ul > li > ul {
    width: 100%;
    display: block;
  }
  #cssmenu.align-right ul li a {
    text-align: left;
  }
  #cssmenu > ul > li > ul > li {
    width: 100%;
    display: block;
  }
  #cssmenu.align-right ul ul li a {
    text-align: left;
  }
  #cssmenu > ul > li > ul > li > a {
    width: 100%;
    display: block;
  }
  #cssmenu ul li ul li a {
    width: 100%;
  }
  #cssmenu.align-center > ul {
    text-align: left;
  }
  #cssmenu.align-center > ul > li {
    display: block;
  }
  #cssmenu > ul.open {
	background:#06A2D7;
    max-height: 1000px;
  }
  #cssmenu ul {
    width: 100%;
  }
  #cssmenu ul > li {
    float: none;
    width: 100%;
  }
  #cssmenu ul li a {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 11px 20px;
  }
  #cssmenu ul > li:after {
    display: none;
  }
  #cssmenu ul li.has-sub > a:after,
  #cssmenu ul li.has-sub > a:before,
  #cssmenu ul li ul li.has-sub:after,
  #cssmenu ul li ul li.has-sub:before {
    display: none;
  }
  #cssmenu ul li ul,
  #cssmenu ul li ul li ul,
  #cssmenu ul li ul li:hover > ul,
  #cssmenu.align-right ul li ul,
  #cssmenu.align-right ul li ul li ul,
  #cssmenu.align-right ul li ul li:hover > ul {
    left: 0;
    position: relative;
    right: auto;
  }
  #cssmenu ul li ul li,
  #cssmenu ul li:hover > ul > li {
    max-height: 999px;
    position: relative;
    background: none;
  }
  #cssmenu ul li ul li a {
    padding: 8px 20px 8px 35px;
    color: #FFFFFF;
  }
  #cssmenu ul li ul ul li a {
    padding: 8px 20px 8px 50px;
  }
  #cssmenu ul li ul li:hover > a {
    color: #FFFFFF;
  }
  #cssmenu #menu-button {
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 14px 20px 13px 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
  }
  #cssmenu #menu-button:after {
    display: block;
    content: '';
    position: absolute;
    height: 3px;
    width: 20px;
    border-top: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    right: 20px;
    top: 15px;
  }
  #cssmenu #menu-button:before {
    display: block;
    content: '';
    position: absolute;
    height: 3px;
    width: 20px;
    border-top: 2px solid #FFFFFF;
    right: 20px;
    top: 25px;
  }
}

/* CONTENT STYLING */
.column {
	margin:20px auto;
	min-height:509px;
	padding:10px 0;
	width:100%;
}

.column-left,
.column-middle,
#checkatradeScores {
	background:#F0F0F3;
}

.column p,
.column ul,
.column li,
.column img {
	margin:0 auto 22px auto;
	max-width:260px;
	width:100%;
}

.picture-link {
	background:#999999;
	background-position:center;
	background-repeat: no-repeat;
	color:#FFFFFF;
	display:block;
	font-family: fjallaOne, Impact, Arial, Helvetica, sans-serif;
	font-size:24px;
	font-weight:400;
	height:72px;
	margin:0 auto 20px auto;
	max-width:150px;
	padding:25px 55px 0 55px;
	text-align:center;
	text-shadow:1px 1px 2px #000000;
	text-transform:uppercase;
	width:100%;
}

.button {
	-webkit-border-radius:4px;
	-moz-border-radius:4px;
	border-radius:4px;
	color:#FFFFFF;
	display:block;
	margin:0 auto 20px auto;
	max-width:210px;
	padding:16px 25px 0px 25px;
	text-align:center;
	text-shadow:1px 1px 1px rgba(0,0,0,0.5);
	width:80%;
}

.button h3 {
	font-weight:400;
}

.button p {
	line-height:16px;
	padding-bottom:13px;
}

.yellow {
	background: #FFCC00;
}

.yellow:hover, .userSitemap li:hover{
	background: #ecbd00;
}

.blue{
	background: #06A2D7;
}

.blue:hover, .userSitemap li:nth-child(3n+2):hover {
	background: #0a97c7;
}

.purple{
	background: #5F2282;
}

.purple:hover, .userSitemap li:nth-child(3n+3):hover{
	background: #4c1b68;
}

#content #checkatradeScores ul {
	width: 100%;
	max-width: 100%;
	margin: 0 0 10px;
	padding: 0 0 20px;
}

#content #checkatradeScores li:nth-child(3n+2), #content #checkatradeScores li:nth-child(3n+3) {
	background: 0;
}

#content #checkatradeScores ul li {
	font-family: fjallaOne, Impact, Arial, Helvetica, sans-serif;
	font-size: 1.06em;
	background: 0;
	color: #5F2282;
	max-width: 92%;
	margin: 0 0 20px;
	padding: 0 4%;
	text-shadow: none;
}

#content #checkatradeScores ul li:first-child {
	font-size: 1.1em;
}

#content #checkatradeScores ul li:last-child {
	margin: 0;
}

/*
#facebook{
    align-self: center;
}*/

#facebook img{
	border-radius: 8%;
    width: 100%;
}

#twitter img, #instagram img{
	width: 100%;
	border-radius: 4px;
}

#trustpilot {
	width: 100%;
    align-self: center;
    margin: 0 auto;
    text-align: center;
}

#trustpilot.home img {
	min-width: 72px;
	max-width: 144px;
}

#trustpilot img {
	min-width: 144px;
	max-width: 288px;
	align-self: center;
}

a.kent-logo {
	clear:left;
	display:inline-block;
	margin:16px auto auto auto;
	text-align:right;
	vertical-align:top;
	width:40%;
}

.small-logos {
	display: flex;
	flex-direction: column;
}

.g-recaptcha {
    margin: 10px auto;
    width: 304px;
}

.small-logos a {
	display:block;
	margin-bottom:14px;
}

.homeButtons {
	padding-top: 70px;
}

#guarantee {
	float: right;
	width: calc(100% - 520px);
	min-height: 520px;
}

/* FOOTER STYLING */
footer {
	background:#06A2D7;
	clear:both;
	display:block;
	padding:6px 0;
}

footer,
footer a {
	color:#FFFFFF;
}

footer p {
	line-height:16px;
	padding:8px 0;
	margin:0;
}

.slideshow{
	position: relative;
}

header .ezy-clean-tp-mini {
	max-width:200px;
	position:absolute!important;
	top:30px;
	right:0;
}

@media (min-width: 340px) {
	.column {
		-webkit-border-radius:4px;
		-moz-border-radius:4px;
		border-radius:4px;
		max-width:48%;
	}

	.column.large {
		width: 360px;
	}
}

@media (max-width: 601px) {
	.small-mobile-disable {
		display:none !important;
	}
}

@media (min-width: 801px) {
	body {
		padding-top: 20px;
	}
	#content {
		text-align:left;
	}
	.no-mobile {
		display:inherit;
	}
	.mobile-only {
		display:none !important;
	}
	header {
		margin: 0 auto;
		position: relative;
		height: 168px;
		width: 640px;
		text-align: -webkit-center;
	}
	header .ezy-tagline {
		display:block;
		position:absolute;
		left:0;
		top:8px;
	}
	header .tagline {
		position:absolute;
		left:10px;
		top:109px;
	}
	header .phone {
		position: absolute;
		top: 65px;
	}
	#cssmenu > ul {
		display: inline-block;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		-ms-justify-content: space-between;
		justify-content: space-between;
		margin: auto;
		max-width: 98%;
		margin-left: 1%;
		margin-right: 1%;
		padding: 10px 0;
	}
	#cssmenu > ul > li {
		margin: 0;
		padding: 0;
	}
	#cssmenu > ul > li > a {
		padding: 0;
		margin: 0;
	}
	#content {
		margin:0 auto;
		width:680px;
	}
	.slideshow {
		margin:0px auto 0 auto;
		position:relative;
		width:640px;
		-webkit-border-radius:4px;
		-moz-border-radius:4px;
		border-radius:4px;
	}
	.slideshow h2,
	.slideshow p {
		color:#FFFFFF;
	}
	.column {
		display:block;
		float:left;
		margin: 20px 0;
		width:200px;
		max-width:24%;
	}
	.column.large {
		width: 360px;
	}
	.column h2,
	.column p,
	.column ul,
	.column li,
	.column img {
		max-width:160px;
	}
	a.picture-link,
	.column h3 {
		font-size:20px;
	}
	.picture-link {
		height:39px;
		max-width:120px;
		padding:29px 20px;
		text-align:center;
	}
	.column-right {
		margin-top:10px;
	}
	.button {
		width:150px;
	}
	.yellow p {
		max-width:142px;
	}
	.purple p {
		max-width:120px;
	}
	footer {
		padding-bottom:20px;
	}
	.footer-inner {
		margin:0 auto;
		max-width:640px;
	}
	.footerInfo {
		max-width:640px;
	}
	.footer-left {
		float:left;
		text-align:left;
		width:40%;
	}
	.footer-right {
		float:right;
		text-align:right;
		width:60%;
	}
}

@media all and (max-width: 850px),
only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 850px),
only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 850px),
only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 850px),
only screen and (min-device-pixel-ratio: 2) and (max-width: 850px),
only screen and (min-resolution: 192dpi) and (max-width: 850px),
only screen and (min-resolution: 2dppx) and (max-width: 850px) {
	.picture-link {
		width: 80%;
		max-width: 80%;
		padding: 20px 4%;
		background-repeat: no-repeat;
		background-size: 100%;
	}
	.button {
		width: 100%;
	}
	#twitter img, #instagram img, #facebook img {
	}
	#guarantee {
		float: none;
		width: 100%;
	}
}

@media all and (max-width: 800px),
only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 800px),
only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 800px),
only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 800px),
only screen and (min-device-pixel-ratio: 2) and (max-width: 800px),
only screen and (min-resolution: 192dpi) and (max-width: 800px),
only screen and (min-resolution: 2dppx) and (max-width: 800px) {
	.column-small {
		min-height: 300px !important;
	}
	.homeButtons {
		padding-top: 0;
	}
	#cssmenu > ul {
		max-height: 0;
		overflow: hidden;
		-webkit-transition: max-height 0.35s;
		-moz-transition: max-height 0.35s;
		-ms-transition: max-height 0.35s;
		-o-transition: max-height 0.35s;
		transition: max-height 0.35s;
	}
	#cssmenu > ul > li > ul {
		width: 100%;
		display: block;
	}
	#cssmenu.align-right ul li a {
		text-align: left;
	}
	#cssmenu > ul > li > ul > li {
		width: 100%;
		display: block;
	}
	#cssmenu.align-right ul ul li a {
		text-align: left;
	}
	#cssmenu > ul > li > ul > li > a {
		width: 100%;
		display: block;
	}
	#cssmenu ul li ul li a {
		width: 100%;
	}
	#cssmenu.align-center > ul {
		text-align: left;
	}
	#cssmenu.align-center > ul > li {
		display: block;
	}
	#cssmenu > ul.open {
		background:#06A2D7;
		max-height: 1000px;
	}
	#cssmenu ul {
		width: 100%;
	}
	#cssmenu ul > li {
		float: none;
		width: 100%;
	}
	#cssmenu ul li a {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		padding: 11px 20px;
	}
	#cssmenu ul > li:after {
		display: none;
	}
	#cssmenu ul li.has-sub > a:after,
	#cssmenu ul li.has-sub > a:before,
	#cssmenu ul li ul li.has-sub:after,
	#cssmenu ul li ul li.has-sub:before {
		display: none;
	}
	#cssmenu ul li ul,
	#cssmenu ul li ul li ul,
	#cssmenu ul li ul li:hover > ul,
	#cssmenu.align-right ul li ul,
	#cssmenu.align-right ul li ul li ul,
	#cssmenu.align-right ul li ul li:hover > ul {
		left: 0;
		position: relative;
		right: auto;
	}
	#cssmenu ul li ul li,
	#cssmenu ul li:hover > ul > li {
		max-height: 999px;
		position: relative;
		background: none;
	}
	#cssmenu ul li ul li a {
		padding: 8px 20px 8px 35px;
		color: #FFFFFF;
	}
	#cssmenu ul li ul ul li a {
		padding: 8px 20px 8px 50px;
	}
	#cssmenu ul li ul li:hover > a {
		color: #FFFFFF;
	}
	#cssmenu #menu-button {
		display: block;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		padding: 14px 20px 13px 20px;
		text-transform: uppercase;
		color: #FFFFFF;
		cursor: pointer;
	}
	#cssmenu #menu-button:after {
		display: block;
		content: '';
		position: absolute;
		height: 3px;
		width: 20px;
		border-top: 2px solid #FFFFFF;
		border-bottom: 2px solid #FFFFFF;
		right: 20px;
		top: 15px;
	}
	#cssmenu #menu-button:before {
		display: block;
		content: '';
		position: absolute;
		height: 3px;
		width: 20px;
		border-top: 2px solid #FFFFFF;
		right: 20px;
		top: 25px;
	}
	.sFlex{
		width: 100%;
		display: inline-block;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}
	.sFlex .specialF{
		width: 100%;
		display: inline-block;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
		margin-bottom: 10px;
	}
	.sSides{
		width: 100%;
	}
	.sFlex p{
		width: 100%;
	}
	.pEstimator .sFlex .field, .pEstimator .sSides .field{
		width: calc(80% - 50px);
		margin: 0 10%;
	}
	.pEstimator button {
		width: 60%;
		margin: 0 20%;
	}
	header .elfsight-app-d5f24f79-7489-4449-8eb6-adec60c6febd {
		display: flex;
		width: 100%;
		position: relative !important;
		float: none;
		top: 0;
		right: 0;
		align-items: center;
	}
	a.kent-logo {
		text-align: center;
		width: 50%;
	}
	.small-logos {
		width: 50%;
	}
	#guarantee {
		float: none;
		width: 100%;
	}
}


@media (min-width: 850px) {
	header {
		width:790px;
	}
	#cssmenu > ul {
		max-width:830px;
		margin: auto;
		padding:0;
	}
	#cssmenu > ul > li  > a {
		padding:12px 8px;
	}
	#content {
		width:830px;
	}
	.slideshow{
		width:790px;
	}
	.slideshow h2 {
		left:75px;
		top:81px;
	}
	.column {
		min-height:auto;
		width:250px;
	}
	.column.large {
		width: 360px;
	}
	.column h2,
	.column p,
	.column ul,
	.column li,
	.column img {
		max-width:210px;
	}
	a.picture-link,
	.column h3 {
		font-size:24px;
	}
	.picture-link {
		height:72px;
		max-width:150px;
		max-width: calc(100% - 80px);
		padding:25px 30px 0 30px;
	}
	.button {
		width:200px;
	}
	.yellow p {
		max-width:142px;
	}
	.purple p {
		max-width:120px;
	}
	.footer-inner, .footerInfo {
		max-width:790px;
	}
}

@media (min-width: 1000px) {
	h2 {
		font-size:36px;
	}
	header {
		height:191px;
		width: 95%;
	}
	header .ezy-logo {
		max-width:240px;
	}
	header .tagline {
		font-size:20px;
		left:7px;
		line-height:20px;
		top:125px;
	}

	header .phone {
		font-size:48px;
		right:0;
		top:76px;
		position: relative;
		margin-left: 240px;
		max-width: calc(100% - 440px);
		text-align: center;
	}

	#cssmenu > ul {
		max-width:985px;
		margin: auto;
	}
	#cssmenu > ul > li  > a {
		padding: 13px 13px;
	}
	.slideshow{
		max-width:100%;
		width:100%;
	}
	.slideshow h2 {
		left:50px;
		top:119px;
	}
	#content {
		width:95%;
	}
	.column {
		width:300px;
	}
	.column.large {
		width: 360px;
	}
	.column h2,
	.column p,
	.column ul,
	.column li,
	.column img {
		max-width:260px;
		width: 100%;
	}
	.column h2 {
		margin-left:20px;
		max-width:250px;
	}
	.picture-link {
		max-width: calc(100% - 130px);
		padding:25px 55px 0 55px;
	}
	.button {
		background-image:url("../images/button-arrow.png");
		background-position:bottom right;
		background-repeat:no-repeat;
		max-width:250px;
		width:100%;
	}
	.button p {
		max-width:210px;
	}
	.purple p {
		max-width:120px;
	}
	.footer-inner, .footerInfo {
		max-width:95%;
	}
}

@media (min-width: 1500px) {
	#cssmenu ul{
		margin: auto;
	}

	#cssmenu ul li a{
		padding: 13px 13px;
	}
}

/* End of Default Styling */

/* Mitch Extra Styling */
button:disabled, button[disabled], button:disabled:hover, button[disabled]:hover {
	opacity: 0.6;
	cursor: no-drop!important;
}

h1{
	color:#5F2282;
	font-size:42px;
	margin: 30px auto 20px auto;
	width:100%;
	text-align: center;
}
h3{
	color: #5f2282;
	font-size: 20px;
	line-height: 120%;
	margin-bottom: 10px;
	text-transform: none;
}
h4{
	color: #5F2282;
	font-size: 18px;
	line-height: 120%;
}
strong{
	font-weight: 700;
}
.button h3{
	color: inherit;
}
#content ul{
	width: 90%;
	padding: 0 5%;
	margin-bottom: 8px;
	background-color: #f0f0f3;
}
#content li{
	padding: 10px;
	background-color: #FFCC00;
	text-shadow:1px 1px 1px rgba(0,0,0,0.5);
	color: #ffffff;
}
#content li:nth-child(3n+2){
	background-color: #06A2D7;
}
#content li:nth-child(3n+3){
	background-color: #5F2282;
}
#content li a{
	color: inherit;
	display: block;
}
.footerInfo .fNav li a{
	display: block;
}
.mTop{
	margin-top: 30px;
}
.mBot{
	margin-bottom: 30px!important;
}
.mLeftZ{
	margin-left: 0;
}
#map{
	width: 100%;
	height: 600px;
	margin-top: 20px;
}
.rowFlex{
	width: 100%;
}
#content .rowFlex .hServices ul, #content .rowFlex .hServicesHalf ul{
	width: 100%;
	padding: 0;
}
.hServices{
	position: relative;
	width: 32%;
	margin-bottom: 20px;
	cursor: pointer;
	-webkit-animation: dispAll 0.5s linear;
	-moz-animation: dispAll 0.5s linear;
	-o-animation: dispAll 0.5s linear;
	animation: dispAll 0.5s linear;
}
.hServices h2{
	margin-bottom: 15px;
}
.hServices img{
	width: 100%;
	max-width: 100%;
}
.hServices ul{
	margin-top: 10px;
}
.hServices ul li{
	min-height: 55px;
}
.hServices .flip{
	width: calc(100% - 40px);
	max-width: 365px;
	margin: inherit auto;
	padding: 10px 20px 10px 20px;
	background-color: #f0f0f3;
	position: relative;
	overflow: hidden;
	-webkit-animation: flipOut 0.5s linear;
	-moz-animation: flipOut 0.5s linear;
	-o-animation: flipOut 0.5s linear;
	animation: flipOut 0.5s linear;
}
.flipCard .flip{
	-webkit-animation: flipIn 0.5s linear;
	-moz-animation: flipIn 0.5s linear;
	-o-animation: flipIn 0.5s linear;
	animation: flipIn 0.5s linear;
}
.flipCard .front{
	-webkit-animation: fDisp 0.25s linear;
	-moz-animation: fDisp 0.25s linear;
	-o-animation: fDisp 0.25s linear;
	animation: fDisp 0.25s linear;
	animation-fill-mode: both;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
}
.hServices .back{	width: calc(100% - 40px);
	position: absolute;
	top: 10px;
	animation-name: fDisp;
	animation-duration: 0.25s;
	animation-timing-function: linear;
	animation-fill-mode: both;
	-webkit-animation-name: fDisp;
	-webkit-animation-duration: 0.25s;
	-webkit-animation-fill-mode: both;
	-moz-animation-timing-function: linear;
	-moz-animation-name: fDisp;
	-moz-animation-duration: 0.25s;
	-moz-animation-timing-function: linear;
	-moz-animation-fill-mode: both;
	-o-animation-name: fDisp;
	-o-animation-duration: 0.25s;
	-o-animation-timing-function: linear;
	-o-animation-fill-mode: both;
}
.flipCard .back{
	animation-name: bDisp;
	animation-duration: 0.25s;
	animation-timing-function: linear;
	animation-fill-mode: both;
	-webkit-animation-name: bDisp;
	-webkit-animation-duration: 0.25s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-fill-mode: both;
	-moz-animation-name: bDisp;
	-moz-animation-duration: 0.25s;
	-moz-animation-timing-function: linear;
	-moz-animation-fill-mode: both;
	-o-animation-name: bDisp;
	-o-animation-duration: 0.25s;
	-o-animation-timing-function: linear;
	animation-fill-mode: both;
}

@-webkit-keyframes dispAll{
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

@-moz-keyframes dispAll{
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

@-o-keyframes dispAll{
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

@keyframes dispAll{
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

@-webkit-keyframes flipIn{
	from{
		-webkit-transform:	rotateY(90deg); /* Safari */
		-moz-transform:		rotateY(90deg);
		-ms-transform:		rotateY(90deg);
		-o-transform:		rotateY(90deg);
		transform:			rotateY(90deg);
	}
	to{
		-webkit-transform:	rotateY(0deg); /* Safari */
		-moz-transform:		rotateY(0deg);
		-ms-transform:		rotateY(0deg);
		-o-transform:		rotateY(0deg);
		transform:			rotateY(0deg);
	}
}

@-moz-keyframes flipIn{
	from{
		-webkit-transform:	rotateY(90deg); /* Safari */
		-moz-transform:		rotateY(90deg);
		-ms-transform:		rotateY(90deg);
		-o-transform:		rotateY(90deg);
		transform:			rotateY(90deg);
	}
	to{
		-webkit-transform:	rotateY(0deg); /* Safari */
		-moz-transform:		rotateY(0deg);
		-ms-transform:		rotateY(0deg);
		-o-transform:		rotateY(0deg);
		transform:			rotateY(0deg);
	}
}

@-o-keyframes flipIn{
	from{
		-webkit-transform:	rotateY(90deg); /* Safari */
		-moz-transform:		rotateY(90deg);
		-ms-transform:		rotateY(90deg);
		-o-transform:		rotateY(90deg);
		transform:			rotateY(90deg);
	}
	to{
		-webkit-transform:	rotateY(0deg); /* Safari */
		-moz-transform:		rotateY(0deg);
		-ms-transform:		rotateY(0deg);
		-o-transform:		rotateY(0deg);
		transform:			rotateY(0deg);
	}
}

@keyframes flipIn{
	from{
		-webkit-transform:	rotateY(90deg); /* Safari */
		-moz-transform:		rotateY(90deg);
		-ms-transform:		rotateY(90deg);
		-o-transform:		rotateY(90deg);
		transform:			rotateY(90deg);
	}
	to{
		-webkit-transform:	rotateY(0deg); /* Safari */
		-moz-transform:		rotateY(0deg);
		-ms-transform:		rotateY(0deg);
		-o-transform:		rotateY(0deg);
		transform:			rotateY(0deg);
	}
}

@-webkit-keyframes flipOut{
	from{
		-webkit-transform:	rotateY(90deg); /* Safari */
		-moz-transform:		rotateY(90deg);
		-ms-transform:		rotateY(90deg);
		-o-transform:		rotateY(90deg);
		transform:			rotateY(90deg);
	}
	to{
		-webkit-transform:	rotateY(0deg); /* Safari */
		-moz-transform:		rotateY(0deg);
		-ms-transform:		rotateY(0deg);
		-o-transform:		rotateY(0deg);
		transform:			rotateY(0deg);
	}
}

@-moz-keyframes flipOut{
	from{
		-webkit-transform:	rotateY(90deg); /* Safari */
		-moz-transform:		rotateY(90deg);
		-ms-transform:		rotateY(90deg);
		-o-transform:		rotateY(90deg);
		transform:			rotateY(90deg);
	}
	to{
		-webkit-transform:	rotateY(0deg); /* Safari */
		-moz-transform:		rotateY(0deg);
		-ms-transform:		rotateY(0deg);
		-o-transform:		rotateY(0deg);
		transform:			rotateY(0deg);
	}
}

@-o-keyframes flipOut{
	from{
		-webkit-transform:	rotateY(90deg); /* Safari */
		-moz-transform:		rotateY(90deg);
		-ms-transform:		rotateY(90deg);
		-o-transform:		rotateY(90deg);
		transform:			rotateY(90deg);
	}
	to{
		-webkit-transform:	rotateY(0deg); /* Safari */
		-moz-transform:		rotateY(0deg);
		-ms-transform:		rotateY(0deg);
		-o-transform:		rotateY(0deg);
		transform:			rotateY(0deg);
	}
}

@keyframes flipOut{
	from{
		-webkit-transform:	rotateY(90deg); /* Safari */
		-moz-transform:		rotateY(90deg);
		-ms-transform:		rotateY(90deg);
		-o-transform:		rotateY(90deg);
		transform:			rotateY(90deg);
	}
	to{
		-webkit-transform:	rotateY(0deg); /* Safari */
		-moz-transform:		rotateY(0deg);
		-ms-transform:		rotateY(0deg);
		-o-transform:		rotateY(0deg);
		transform:			rotateY(0deg);
	}
}

@-webkit-keyframes fDisp{
	from{
		opacity: 1;
	}
	to{
		opacity: 0;
	}
}

@-moz-keyframes fDisp{
	from{
		opacity: 1;
	}
	to{
		opacity: 0;
	}
}

@-o-keyframes fDisp{
	from{
		opacity: 1;
	}
	to{
		opacity: 0;
	}
}

@keyframes fDisp{
	from{
		opacity: 1;
	}
	to{
		opacity: 0;
	}
}

@-webkit-keyframes bDisp{
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

@-moz-keyframes bDisp{
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

@-o-keyframes bDisp{
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

@keyframes bDisp{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
.button, .submit input{
	cursor: pointer;
}

.button a{
	display: block;
	color: inherit;
	text-decoration: none;
	width: 100%;
}

.button h3{
	width: 100%;
}

.button p{
	width: 100%;
	max-width: 90%;
}
.smallB{
	max-width: 160px;
	text-align: left;
}
.smallB p{
	max-width: 160px;
}

.clickArea{
	width: 70%;
	max-width: 686px;
	margin: 0 auto;
	padding: 10px 2em 0;
	background-color: #06A2D7;
	cursor: pointer;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.clickArea p{
	text-align: center;
	color: #ffffff;
	font-size: 160%;
	margin-top: 10px;
	padding-bottom: 10px;
}
.beforeAfter{
	position: relative;
	width: 100%;
	max-width: 100%;
	height: auto;
}
.beforeAfter #slide_holder{
	max-width: 686px;
	max-height: 592px;
	min-height: 592px;
}
.beforeAfter #slideOv_holder{
	display: none;
}
.slideshow{
	height: auto;
}
.slideshow a{
	color: #ffffff;
	text-decoration: none;
	font-weight: inherit;
}
.slideshow p{
	width: 100%;
	z-index: 20;
}
#slideshow_holder{
	width: 100%;
	cursor: pointer;
}
#slide_holder, #slideOv_holder{
	width: 100%;
	height: 100%;
}

#slide_holder .slide, #slideOv_holder .ovSlide{
	width: 100%;
	width: 100vw;
	max-width: 100%;
	max-width: 100vw;
	height: 100%;
	max-height: 100%;
	max-height: 41.0612vw;
}

.beforeAfter #slide_holder .slide {
	width: 100%;
	max-width: 100%;
	max-height: 100%;
}

.slideshow #slide_holder, .slideshow #slide_holder .slide{
	min-height: 650px;
	min-height: 41.0612vw;
}

.slide img{
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
}

#slideOv_holder{
	position: absolute;
	top: 0;
	width: 100%;
	height: 650px;
	height: 41.0612vw;
	z-index: 10;
}

#slideOv_holder .ovSlide{
	width: 100%;
	height: 650px;
	height: 41.0612vw;
}


#slideOv_holder .ovPos {
	width: 78.6%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

#slideOv_holder .ovSlide:nth-child(4n + 2) .ovPos, #slideOv_holder .ovSlide:nth-child(4n + 3) .ovPos{
	right: 0;
	left: auto!important;
}

#slideOv_holder .overlay{
	position: absolute;
	color: #ffffff;
	background-color: rgba(80, 34, 130, 0.4);
	width: 34%;
	height: 40%;
}

#slideOv_holder .ovSlide:nth-child(4n + 2) .overlay, #slideOv_holder .ovSlide:nth-child(4n + 3) .overlay{
	right: 0;
	left: auto!important;
}

#slideOv_holder .ovSlide:nth-child(4n + 2) .overlay, #slideOv_holder .ovSlide:nth-child(4n + 4) .overlay{
	bottom: 0;
	top: auto!important;
}

#slideOv_holder .ovSlide:nth-child(4n + 1) .overlay:before, #slideOv_holder .ovSlide:nth-child(4n + 1) .overlay:after, #slideOv_holder .ovSlide:nth-child(4n + 2) .overlay:before, #slideOv_holder .ovSlide:nth-child(4n + 2) .overlay:after, #slideOv_holder .ovSlide:nth-child(4n + 3) .overlay:before, #slideOv_holder .ovSlide:nth-child(4n + 3) .overlay:after, #slideOv_holder .ovSlide:nth-child(4n + 4) .overlay:before, #slideOv_holder .ovSlide:nth-child(4n + 4) .overlay:after{
    content: "";
    position: absolute;
}

#slideOv_holder .ovSlide:nth-child(4n + 1) .overlay:before{
	border-right: 26.72141503474416vw solid rgba(80, 34, 130, 0.4);
    border-bottom: 24.88945041061276vw solid transparent;
	bottom: -24.85945041061276vw;
	right: 0;
}

#slideOv_holder .ovSlide:nth-child(4n + 1) .overlay:after{
	border-left: 46.68351231838282vw solid rgba(80, 34, 130, 0.4);
    border-top: 41.0612vw solid transparent;
	top: 0;
	right: -46.65351231838282vw;
}

#slideOv_holder .ovSlide:nth-child(4n + 2) .overlay:before{
	border-left: 26.72141503474416vw solid rgba(80, 34, 130, 0.4);
    border-top: 24.88945041061276vw solid transparent;
	top: -24.85945041061276vw;
	left: 0;
}

#slideOv_holder .ovSlide:nth-child(4n + 2) .overlay:after{
	border-right: 46.68351231838282vw solid rgba(80, 34, 130, 0.4);
    border-bottom: 41.0612vw solid transparent;
	bottom: 0;
	left: -46.65351231838282vw;
}

#slideOv_holder .ovSlide:nth-child(4n + 3) .overlay:before{
	border-left: 26.72141503474416vw solid rgba(80, 34, 130, 0.4);
    border-bottom: 24.88945041061276vw solid transparent;
	bottom: -24.85945041061276vw;
	left: 0;
}

#slideOv_holder .ovSlide:nth-child(4n + 3) .overlay:after{
	border-right: 46.68351231838282vw solid rgba(80, 34, 130, 0.4);
	border-top: 41.0612vw solid transparent;
	top: 0;
	left: -46.65351231838282vw;
}

#slideOv_holder .ovSlide:nth-child(4n + 4) .overlay:before{
	border-right: 26.72141503474416vw solid rgba(80, 34, 130, 0.4);
    border-top: 24.88945041061276vw solid transparent;
	top: -24.85945041061276vw;
	right: 0;
}

#slideOv_holder .ovSlide:nth-child(4n + 4) .overlay:after{
	border-left: 46.68351231838282vw solid rgba(80, 34, 130, 0.4);
	border-bottom: 41.0612vw solid transparent;
	bottom: 0;
	right: -46.65351231838282vw;
}

#slideOv_holder .ovSlide p {
	font-size: 22px;
	line-height: 1.3em;
	position: absolute;
}

#slideOv_holder .ovSlide:nth-child(4n + 1) p, #slideOv_holder .ovSlide:nth-child(4n + 3) p {
	top: 100%;
}

#slideOv_holder .ovSlide:nth-child(4n + 2) p {
	right: 55%;
}

#slideOv_holder .ovSlide:nth-child(4n + 3) p {
	right: 40%;
}

#slideOv_holder .ovSlide:nth-child(4n + 2) p, #slideOv_holder .ovSlide:nth-child(4n + 4) p {
	bottom: 100%;
}

#slideOv_holder .ovSlide:nth-child(4n + 1) p {
	left: 40%;
}

#slideOv_holder .ovSlide:nth-child(4n + 4) p {
	left: 55%;
}

.leftLarge{
	width: 75%;
}

.rightSmall{
	width: 24%;
}

.rightSmall form{
	margin-top: 30px;
}

.rightSmall form .inline{
	background: #ffcc00;
	font-weight: 400;
	text-shadow: none;
	color: #4c1b68;
    border-radius: 4px;
    padding: 15px 20px;
	margin-bottom: 10px;
    width: calc(100% - 40px);
}

.rightSmall form .message{
	background: #ffcc00;
	font-weight: 400;
	text-shadow: none;
	color: #4c1b68;
    border-radius: 4px;
    padding: 15px 20px;
	margin-bottom: 10px;
    width: calc(100% - 40px);
}

.rightSmall form .inline div, .rightSmall form .message div, .rightSmall .submit div{
	width: 100%;
	padding: 0px;
	margin: 0px;
}

.rightSmall form .inline input, .rightSmall form .message textarea{
	border: 1px solid #ffcc00;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
    font-size: 100%;
	width: calc(100% - 8px);
	padding: 2px 3px;
}

.rightSmall form .inline input:focus, .rightSmall form .message textarea:focus{
	border: 1px solid #5f2282;
}

.rightSmall form .message textarea{
	min-width: calc(100% - 4px);
	max-width: calc(100% - 4px);
	min-height: 70px;
	height: 70px;
	max-height: 200px;
}

.rightSmall .submit{
	width: 100%;
}

.rightSmall .submit button{
	background-color: #5f2282;
    border: 0 none;
    border-radius: 4px;
    color: #ffffff;
    display: block;
    font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
    font-size: 100%;
    margin-bottom: 20px;
    padding: 12px 25px;
    text-align: center;
    width: 100%;
}

.rightSmall .submit button:hover{
	background-color: #461a60;
}

.success, .error, .danger{
	width: 100%;
	margin-top: 10px;
	padding: 15px 0;
	text-align: center;
	background-color: rgba(24, 173, 31, 0.6);
	color: #ffffff;
}

.error, .danger{
	background-color: rgba(212, 29, 29, 0.6);
}

.customerInv{
	margin: 30px 0;
}

.customerInv h4{
	font-size: 20px;
	padding: 10px 0;
}

#logOut{
	background-color: #5f2282;
	border: 0 none;
	border-radius: 4px;
	color: #ffffff;
	display: block;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	margin-bottom: 20px;
	padding: 12px 25px;
	text-align: center;
	width: 30%;
}

#logOut:hover{
	background-color: #461a60;
    text-decoration: none;
}

.customerInfo{
	width: 30%;
	font-size: 110%;
}
	
.priceFlex .openForm{
	align-self: flex-start;
	margin-top: 30px;
}
	
.priceFlex .openForm p{
	margin-bottom: 0;
}

.addInfo{
	display: none!important;
}

#sendMessage, #addressOpener{
	width: calc(30% - 50px);
	align-self: flex-start;
	font-size: 100%;
	-webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    color: #FFFFFF;
    display: block;
    padding: 12px 25px;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
	background-color: #5F2282;
	cursor: pointer;
}

#addressOpener{
    padding: 9px 25px;
	font-weight: 700;
}

#sendMessage:hover, #addressOpener:hover{
	background-color: #461a60;
}
.customerInfo p{	margin-bottom: 0px;
}

.colFlex{
	width: 55%;
}
#paymentForm{
	margin-bottom: 30px;
}

#paymentForm button{
	background-color: #5f2282;
	border: 0 none;
	border-radius: 4px;
	color: #ffffff;
	display: block;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	margin-bottom: 20px;
	padding: 12px 25px;
	text-align: center;
	width: 30%;
	cursor: pointer;
}

#paymentForm button:hover{
	background-color: #461a60;
}
.invoices{
	width: 80%;
	margin: 20px 10%;
}
.invoices tr{
	border-bottom: 1px solid #06a2d7;
}
.invoices tr:first-child{
	background-color: #06a2d7;
	color: #ffffff;
	font-size: 120%;
	padding: 10px 0;
}
.invoices th, #invoices td{
	width: 20%;	text-align: center;
}
.invoices td{	padding: 15px 0;
	align-self: center;
}

.invoices td:last-child{	padding: 0;
	margin: 5px 0;
}

.invoices button{	background-color: #5f2282;
	border: 0;
	border-radius: 4px;
	color: #ffffff;
	display: block;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	padding: 12px 25px;	text-align: center;
	width: 100%;
}

.log{
	margin: 30px 0;
}

.log .field{
	background: #ffcc00;
	text-shadow:1px 1px 1px rgba(0,0,0,0.5);
	border-radius: 4px;
	color: #ffffff;
	margin: 0 10% 30px;
	padding: 30px 40px;
	width: calc(80% - 80px);
}

.log .field label{
	font-weight: 700;
	text-shadow: none;
	color: #4c1b68;
	align-self: center;
}

.log .field input{
	border: 1px solid #ffcc00;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
    padding: 2px 3px;
    width: calc(65% - 8px);
	align-self: center;
}

.log .field a{
	text-decoration: underline;
	text-shadow: none;
	color: #4c1b68;
}

.log .field p{
	width: 100%;
	text-align: right;
	margin-top: 10px;
	margin-bottom: -10px;
}

.log .field input:focus{
	border: 1px solid #5f2282;
}
.log a{
	color: #ffffff;
}
.log button{
	background-color: #5f2282;
	border: 0 none;
	border-radius: 4px;
	color: #ffffff;
	display: block;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	padding: 12px 25px;
	text-align: center;
	width: 40%;
	margin-left: 50%;
	cursor: pointer;
}
.log button:hover{
	background-color: #461a60;
}
.youtube{
	min-height: 315px;
	width: 60%;
	margin: 0 20% 30px;
}
blockquote{
	position: relative;
	line-height: 22px;
    margin: 0 0 22px;
	padding: 20px 50px;
}

.sidebar blockquote{
	width: 60%;
	margin-left: 2.5%}

blockquote:before{
	content: "";
	height: 42px;
	width: 42px;
	position: absolute;
	top: 0;
	left: 0;
}

blockquote:after{
	content: "";
	height: 42px;
	width: 42px;
	position: absolute;
	bottom: 0;
	right: 0;
}

blockquote h3{
	margin-top: 20px;
}

.sidebar img{
	float: right;
	margin: 0px 10px 20px;
	max-width: 20%;
}

.singlePay{
	margin-bottom: 30px;
}

.singlePay h3{
	max-width: 100%;
	margin-bottom: 30px;
}

.singlePay p{
	width: 100%;
}

.singlePay .field{
	position: relative;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	color: #FFFFFF;
	display: block;
	margin-bottom: 20px;
	padding: 20px 25px;
	width: calc(48% - 50px);
	background: #FFCC00;
	text-shadow:1px 1px 1px rgba(0,0,0,0.5);
}

#content .errors {
	font-size: 0.8em;
	font-weight: 700;
	padding: 0;
	margin: 2px 0 5px;
	background: none;
}

#content .singlePay .errors {
	padding: 0;
	margin: 0;
	position: absolute;
	bottom: -1em;
	left: 0;
	background: none;
}

#content .errors li {
    padding: 0;
    background: none;
    color: red;
    text-shadow: none;
}

#content .userSitemap {
    background: none;
}

.singlePay .field div {
	align-self: center;
}

.singlePay .field label{
	width: 100%;
	font-weight: 700;
	text-shadow: none;
	color: #4c1b68;
}

.singlePay .field select{
	width: 40%;
	color: #000000;
	border: 0;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	align-self: center;
	margin-left: 10px;
}

.singlePay .field input{
	width: 40%;
	color: #000000;
	border: 0;
	font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
	font-size: 100%;
	align-self: center;
	margin-left: 10px;
}

.singlePay button[type="submit"] {
	background: #5f2282;
    border: 0;
    border-radius: 4px;
    color: #ffffff;
    display: block;
    font-family: quattrocentoSans, Arial, Helvetica, sans-serif;
    font-size: 100%;
	margin-bottom: 20px;
    padding: 12px 30px;
    text-align: center;
    width: 48%;
}

.singlePay .sagePay {
	padding: 2.4em 0;
}

.hContent{
	display: inline-block;
	width: 55%;
	width: calc(100% - 420px);
	overflow: hidden;
	margin-bottom: 20px;
	color: #000000;
	background-color: #f0f0f3;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	-webkit-transition: max-height ease-in-out 1.5s;
	-moz-transition: max-height ease-in-out 1.5s;
	-o-transition: max-height ease-in-out 1.5s;
	transition: max-height ease-in-out 1.5s;
}

.acc .hContent:first-child{
	max-height: 580px;
}

.acc .hContent:nth-child(2){
	max-height: 180px;
}

.acc .hContent:nth-child(3){
	max-height: 300px;
}

.acc .hContent:nth-child(4){
	max-height: 220px;
}

.acc .hContent:nth-child(5){
	max-height: 240px;
}

.acc .hContent:nth-child(6){
	max-height: 270px;
}

.openContent{
	max-height: 44px!important;
}

.hContent h3{
	width: calc(100% - 40px);
	padding: 10px 20px;
	margin: 0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	cursor: pointer;
}

.hContent p{
	padding-top: 10px;
	padding-right: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
	margin-bottom: 0;
}

.hContent p:last-child{
	margin-bottom: 20px;
}

.hContent:nth-child(3n + 1) h3{
	background-color: #FFCC00;
	text-shadow:1px 1px 1px rgba(0,0,0,0.5);
	color: #5F2282;
}

.hContent:nth-child(3n + 2) h3{
	background-color: #06A2D7;
	color: #FFCC00;
}

.hContent:nth-child(3n + 3) h3{
	background-color: #5F2282;
	color: #06A2D7;
}

.payIcons {
	width: 25%;
	align-self: center;
	margin: 20px 0;
	cursor: pointer;
}

.payText{
	display: none;
}
#whyChoose{
	width: 40%;	max-width: 400px;
	float: right;
}

.checkApp{
	display: none!important;
}

.socialLogos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: 200px;
    column-gap: 10px;
    margin-bottom: 20px;
}

.socialLogos a {
    width: 50px;
}

@media(width <= 800px) {
    .socialLogos {
        margin: auto;
    }
}

footer a:hover{
	text-decoration: none;
}

.footerInfo{
	width: 100%;
	max-width: 95%;
	margin: auto;
	padding: 5px 0;
}

.footerInfo .fNav{
	width: 38%;
	align-self: flex-start;
	text-align: left;
}

.footerInfo .fNav li{
	line-height: 120%;
}

.footerInfo .fButtons{
	width: 20%;
	align-self: flex-start;
}

.footerInfo .fButtons .button{
	width: calc(100% - 20px);
	max-width: 100%;
	background-image: none;
	padding: 10px 10px;
}

.footerInfo .fButtons .button p{
	max-width: 100%;
	text-align: center;
	padding: 0;
}

.footerInfo .fButtons .blue{
	background-color: #0e8bb5;
}

.footerInfo .fButtons .blue:hover{
	background-color: #0d7ea5;
}

.footerInfo .fButtons .blue a:hover{
	color: #ffcc00;
}

.footerInfo .fButtons .purple{
	margin-bottom: 0;
}

.footerInfo .fButtons .purple a:hover{
	color: #06a2d7;
}

.footerInfo .fAddress{
	width: 38%;
	align-self: flex-start;
	text-align: right;
}

.footerInfo .fAddress p{
	line-height: 120%;
	margin: 0;
	padding: 0;
}

#transferArea {
	padding: 20px 0;
}

.debitNotify {
	margin: 20px 0;
}

.debitNotify img {
	max-width: 160px;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1496px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 1496px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 1496px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 1496px), 
only screen and (in-resolution: 72dpi) and (max-width: 1496px), 
only screen and (min-resolution: 1dppx) and (max-width: 1496px), 
only screen and (max-width: 1496px) {
	/* #slideOv_holder .ovSlide:nth-child(4n + 1) .overlay:before{
		border-right: 26.58vw solid rgba(80, 34, 130, 0.4);
		border-bottom: 24.87vw solid transparent;
		bottom: -24.87vw;
	}
	
	#slideOv_holder .ovSlide:nth-child(4n + 1) .overlay:after{
		border-left: 46.4vw solid rgba(80, 34, 130, 0.4);
		border-top: 41.1vw solid transparent;
		top: 0;
		right: -46.35vw;
	}
	
	#slideOv_holder .ovSlide:nth-child(4n + 2) .overlay:before{
		border-left: 26.58vw solid rgba(80, 34, 130, 0.4);
		border-top: 24.87vw solid transparent;
		top: -24.87vw;
	}
	
	#slideOv_holder .ovSlide:nth-child(4n + 2) .overlay:after{
		border-right: 46.4vw solid rgba(80, 34, 130, 0.4);
		border-bottom: 41.1vw solid transparent;
		bottom: 0;
		left: -46.35vw;
	}
	
	#slideOv_holder .ovSlide:nth-child(4n + 3) .overlay:before{
		border-left: 26.58vw solid rgba(80, 34, 130, 0.4);
		border-bottom: 24.87vw solid transparent;
		bottom: -24.87vw;
	}
	
	#slideOv_holder .ovSlide:nth-child(4n + 3) .overlay:after{
		border-right: 46.4vw solid rgba(80, 34, 130, 0.4);
		border-top: 41.1vw solid transparent;
		top: 0;
		left: -46.35vw;
	}
	
	#slideOv_holder .ovSlide:nth-child(4n + 4) .overlay:before{
		border-right: 26.58vw solid rgba(80, 34, 130, 0.4);
		border-top: 24.87vw solid transparent;
		top: -24.87vw;
	}
	
	#slideOv_holder .ovSlide:nth-child(4n + 4) .overlay:after{
		border-left: 46.4vw solid rgba(80, 34, 130, 0.4);
		border-bottom: 41.1vw solid transparent;
		bottom: 0;
		right: -46.35vw;
	} */
}

@media (max-width: 1200px) {
	#map{
		height: 480px;
	}
}

@media 
only screen and (-webkit-max-device-pixel-ratio: 1) and (min-width: 1399px) and (max-width: 1401px), 
only screen and (-ms-max-device-pixel-ratio: 1/1) and (min-width: 1399px) and (max-width: 1401px) {
	#slideOv_holder .ovSlide:nth-child(4n + 1) .overlay:after{
		right: -46.659vw;
	}
	#slideOv_holder .ovSlide:nth-child(4n + 2) .overlay:after{
		left: -46.659vw;
	}
	#slideOv_holder .ovSlide:nth-child(4n + 3) .overlay:after{
		left: -46.659vw;
	}
	#slideOv_holder .ovSlide:nth-child(4n + 4) .overlay:after{
		right: -46.659vw;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1248px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 1248px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 1248px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 1248px), 
only screen and (in-resolution: 72dpi) and (max-width: 1248px), 
only screen and (min-resolution: 1dppx) and (max-width: 1248px), 
only screen and (max-width: 1248px) {
	.column{
		width: 23%;
		margin-bottom: 20px;
		max-width: 23%;
	}

	.column h2, .column p, .column ul, .column li, .column img{
		max-width: 90%;
	}

	#slideOv_holder .overlay p{
		font-size: 18px;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1024px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 1024px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 1024px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 1024px), 
only screen and (in-resolution: 72dpi) and (max-width: 1024px), 
only screen and (min-resolution: 1dppx) and (max-width: 1024px), 
only screen and (max-width: 1024px){
	.slideshow{
		width: 100%;
		margin-bottom: 100px;
	}
	.slideshow #slideshow_holder{
		position: relative;
	}
	.slideshow img{
		width: 100%!important;
		height: 100%!important;
	}
	#slideOv_holder{
		width: 100%!important;
		height: 90px;
		top: auto;
		bottom: -88px;
	}
	#slideOv_holder .ovPos, #slideOv_holder .ovPos .overlay{
		height: 90px;
	}
	#slideOv_holder .overlay{
		position: static;
		display: inline-block;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: center;
		-moz-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
	}
	#slideOv_holder .overlay p{
		font-size: 17px;
		width: 95%;
		margin-top: 30px;
		margin-left: 2.5%;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder a:nth-child(4n + 3) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder a:nth-child(4n + 4) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder .ovPos {
		width: 100%;
	}
	#slideOv_holder .ovPos .overlay{
		width: 90%;
		padding: 0 5%;
	}
	#slideOv_holder .ovPos .overlay p, #slideOv_holder a:nth-child(4n + 2) .ovPos .overlay p, #slideOv_holder a:nth-child(4n + 3) .ovPos .overlay p, #slideOv_holder a:nth-child(4n + 4) .ovPos .overlay p{
		width: 100%;
		padding: 0!important;
		margin: 0!important;
		position: static;
		top: 0!important;
		right: 0!important;
		bottom: 0!important;
		left: 0!important;
		font-size: 18px;
	}
	.ovPos .overlay:before, .ovPos .overlay:after{
		display: none;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay, #slideOv_holder a:nth-child(4n + 3) .ovPos .overlay{
		right: inherit;
		left: inherit!important;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay, #slideOv_holder a:nth-child(4n + 4) .ovPos .overlay{
		top: inherit;
	}
	.slideshow .slide img{
		width: auto;
		height: 392px;
	}
	.hServices h2{
		font-size: 30px;
		margin-top: 10px;
	}
	.hServices img{
		margin-top: 10px;
		margin-bottom: 10px;
	}
	#content .hServices ul{
		font-size: 120%;
	}
	.hServices ul li{
		min-height: 70px;
	}
	.customerInv h4 {
		max-width: 30%;
	}
	#logOut{
		align-self: flex-end;
	}
	#content .hServices ul{
		font-size: 90%;
		margin-bottom: 10px;
	}
	#whyChoose{
		width: 40%;
	}
	#vidSpacing {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}
	.leftLarge{
		width: 100%;
		margin-bottom: 10px;
	}
	.rightSmall{
		width: 40%;
		margin-bottom: 10px;
	}
	.rightSmall form {
		margin-top: 10px;
	}
	.singlePay .field {
		width: calc(60% - 50px);
		margin-left: 20%;
	}
	.singlePay .field div {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.singlePay .field select, .singlePay .field input{
		width: 80%;
		margin-top: 10px;
		margin-left: 10%;
		margin-right: 10%;
	}
	.singlePay button {
		width: 48%;
		margin-left: 32%;
	}
}

@media (max-width: 850px) {
	.hServices h2{
		font-size: 23px;
		margin-top: 10px;
	}
	#content .hServices ul{
		font-size: 90%;
		margin-bottom: 10px;
	}
	.hServices ul li{
		min-height: 40px;
	}
	.singlePay .field {
		width: calc(80% - 50px);
		margin-left: 10%;
	}
	.singlePay button {
		width: 48%;
		margin-left: 42%;
	}
}

@media 
only screen and (-webkit-max-device-pixel-ratio: 1) and (min-width: 768px) and (max-width: 1024px), 
only screen and (-max-moz-device-pixel-ratio: 1) and (min-width: 768px) and (max-width: 1024px), 
only screen and (-o-max-device-pixel-ratio: 1/1) and (min-width: 768px) and (max-width: 1024px), 
only screen and (max-device-pixel-ratio: 1) and (min-width: 768px) and (max-width: 1024px),
only screen and (max-resolution: 1dppx) and (min-width: 768px) and (max-width: 1024px), 
only screen and (min-width: 768px) and (max-width: 1024px) {
	.slideshow .slide img{
		width: auto;
		height: 318px;
	}
	.beforeAfter #slide_holder {
		min-height: 700px;
	}
	.column{
		width: 48%;
		margin-bottom: 20px;
		max-width: 48%;
	}
	.hServices{
		width: 48%;
		margin-bottom: 20px;
		max-width: 100%;
	}
	.hServices .flip {
		max-width: 100%;
	}
	.hServices h2{
		font-size: 30px;
		margin-top: 10px;
	}
	.hServices img{
		margin-top: 10px;
		margin-bottom: 10px;
	}
	#content .hServices ul{
		font-size: 120%;
	}
	.hServices ul li{
		min-height: 70px;
	}
	.customerInv{
		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.customerInv h4 {
		max-width: 48%;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 20px;
	}
	#logOut{
		align-self: flex-end;
	}
	#content .hServices ul{
		font-size: 90%;
		margin-bottom: 10px;
	}
	#whyChoose{
		width: 40%;
	}
	#vidSpacing {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}
	.leftLarge{
		width: 100%;
		margin-bottom: 10px;
	}
	.rightSmall{
		width: 40%;
		margin-bottom: 10px;
	}
	.rightSmall form {
		margin-top: 10px;
	}
	.singlePay .field {
		width: calc(60% - 50px);
		margin-left: 20%;
	}
	.singlePay button {
		width: 48%;
		margin-left: 32%;
	}
	.footer-left {
		float:left;
		text-align:left;
		width: 39%;
		margin-left: 1%;
	}
	.footer-right {
		float:right;
		text-align:right;
		width:59%;
		margin-right: 1%;
	}
	.footerInfo .fNav {
		align-self: flex-start;
		margin: 0;
		text-align: left;
		width: 38%;
		margin-left: 1%;
	}
	.footerInfo .fButtons {
		width: 20%;
	}
	.footerInfo .fAddress {
		align-self: flex-start;
		margin: 0;
		text-align: right;
		width: 38%;
		margin-right: 1%;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 800px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 800px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 800px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 800px), 
only screen and (in-resolution: 72dpi) and (max-width: 800px), 
only screen and (min-resolution: 1dppx) and (max-width: 800px), 
only screen and (max-width: 800px) {
	#content p{
		width: 98%;
		margin-left: 1%;
		margin-right: 1%;
	}
	.slideshow{
		width: 100%;
		margin-bottom: 100px;
	}
	.slideshow #slideshow_holder{
		position: relative;
	}
	.slideshow img{
		width: 100%!important;
		height: 100%!important;
	}
	#slideOv_holder .ovPos, #slideOv_holder .ovPos .overlay{
		height: 90px;
	}
	#slideOv_holder .overlay p{
		width: 100%;
		margin: 0;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder a:nth-child(4n + 3) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder a:nth-child(4n + 4) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder .ovPos .overlay{
		width: 90%;
	}
	.ovPos .overlay:before, .ovPos .overlay:after{
		display: none;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay, #slideOv_holder a:nth-child(4n + 3) .ovPos .overlay{
		right: inherit;
		left: inherit!important;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay, #slideOv_holder a:nth-child(4n + 4) .ovPos .overlay{
		top: inherit;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay p, #slideOv_holder a:nth-child(4n + 3) .ovPos .overlay p, #slideOv_holder a:nth-child(4n + 4) .ovPos .overlay p{
		top: inherit!important;
		right: inherit!important;
		bottom: inherit!important;
		left: inherit!important;
	}
	.column{
		width: 80%;
		max-width: 80%;
		margin: 5px 10% 20px;
	}
	.hServices{
		width: 80%;
		margin-left: 10%;
		margin-bottom: 20px;
	}
	.hServices .flip {
		max-width: 100%;
	}
	.hServices h2{
		font-size: 28px;
		margin-top: 10px;
	}
	#content .hServices ul{
		font-size: 120%;
		margin-top: 30px;
	}
	.hServices ul li{
		min-height: 70px;
		margin-bottom: 20px;
	}
	.leftLarge, .rightSmall{
		width: 100%;
		margin-bottom: 10px;
	}
	.rightSmall form .inline, .rightSmall form .message{
		border-radius: 0;
		margin-bottom: 0px;
	}
	.rightSmall .submit{
		width: 80%;
		margin: 10px 10% 0;
	}
	#invoices{
		width: 100%;
		margin: 20px 0;
	}
	#invoices tr{
		width: calc(100% - 10px);
		padding: 0 5px;
	}
	#invoices tr:first-child{
		width: 100%;
		padding: 10px 0;
	}
	#invoices th, #invoices td{
		width: 20%;
	}
	#invoices td{
		padding: 15px 0;
	}
	#invoices th:last-child, #invoices td:last-child{
		width: 30%;
		padding: 0;
		margin: 5px 0;
	}
	#whyChoose{
		width: 80%;
		margin: 0 10%;
		float: none;
	}
	.youtube{
		margin: 0 10% 30px;
		width: 80%;
	}
	.beforeAfter #slide_holder {
		min-height: 530px;
	}	
	#map{
		width: 80%;
		margin-left: 10%;
		margin-right: 10%;
		height: 400px;
	}
	.payIcons {
		margin: 20px 2.5%;
		width: 43%;
	}
	.pEstimator .specialF .spPad{
		height: auto;
		margin-bottom: 10px;
	}
	.pEstimator .specialF .spPad:last-child {
		margin-bottom: inherit;
	}
	.sFlex .sFlex div {
		display: inline-block;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		-ms-justify-content: space-between;
		justify-content: space-between;
	}
	.pEstimator .field label {
		font-weight: 700;
		text-shadow: none;
		color: #4c1b68;
		line-height: 18px;
		max-width: 60%;
		width: auto;
	}
	.servicePrices {
		width: 95%;
		margin-left: 2.5%;
		margin-right: 2.5%;
	}
	.contactInfo {
		width: calc(100% - 50px);
	}
	.priceFlex{
		display: inline-block;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		-ms-justify-content: space-between;
		justify-content: space-between;
		width: 100%;
	}
	.colFlex {
		width: 100%;
	}
	.contactInfo {
		margin-left: 10%;
		width: calc(80% - 50px);
	}
	.singlePay .field {
		width: calc(93% - 50px);
		margin-left: 2.5%;
		margin-right: 2.5%;
	}
	.singlePay button {
		width: 93%;
		margin-left: 2.5%;
		margin-right: 2.5%;
	}
	.customerInv{
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.customerInv h4 {
		width: 60%;
		max-width: 60%;
		margin-left: 20%;
		margin-right: 20%;
	}
	#logOut {
		width: calc(60% - 50px);
		margin-left: 20%;
		margin-right: 20%;
	}
	.customerInfo {
		width: 60%;
		margin-left: 20%;
		margin-right: 20%;
	}
	#addInfo {
		margin-top: 20px;
	}
	#addressOpener, #sendMessage{
		width: calc(60% - 50px);
		margin-left: 20%;
		margin-right: 20%;
		margin-top: 20px;
	}
	#invoices th:last-child, #invoices td:last-child {
		width: 10%;
	}
	#paymentForm button {
		width: 60%;
		margin-left: 20%;
		margin-right: 20%;
	}
	.hContent{
		width: 90%;
		margin-left: 5%;
		margin-right: 5%;
	}
	.hContent p{
		width: calc(98% - 40px)!important;
	}
	.button p {
		width: 98%;
		max-width: 98%;
		padding: 0 1% 13px;
	}
	.mLeftZ{
		margin-left: 10%;
	}
	.footerInfo .fNav{
		width: 98%;
		margin: 5px 1% 15px;
		text-align: center;
	}
	.footerInfo .fNav li{
		padding: 5px 0;
	}
	.footerInfo .fButtons{
		width: 98%;
		margin-left: 1%;
		margin-right: 1%;
	}
	.footerInfo .fAddress{
		width: 98%;
		margin: 15px 1% 5px;
		text-align: center;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 768px) and (orientation: landscape), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 768px) and (orientation: landscape), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 768px) and (orientation: landscape), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 768px) and (orientation: landscape), 
only screen and (in-resolution: 72dpi) and (max-width: 768px) and (orientation: landscape), 
only screen and (min-resolution: 1dppx) and (max-width: 768px) and (orientation: landscape), 
only screen and (max-width: 768px) and (orientation: landscape) {
	.slideshow .slide img{
		width: auto;
		height: 392px;
	}
	.hServices{
		width: 80%;
		margin-bottom: 20px;
	}
	.hServices h2{
		font-size: 30px;
		margin-top: 10px;
	}
	.hServices img{
		margin-top: 10px;
		margin-bottom: 10px;
	}
	#content .hServices ul{
		font-size: 120%;
	}
	.hServices ul li{
		min-height: 70px;
	}
	.customerInv h4 {
		max-width: 30%;
	}
	#logOut{
		align-self: flex-end;
	}
	#content .hServices ul{
		font-size: 90%;
		margin-bottom: 10px;
	}
	#whyChoose{
		width: 40%;
	}
	#vidSpacing {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}
	.leftLarge{
		width: 100%;
		margin-bottom: 10px;
	}
	.rightSmall{
		width: 40%;
		margin-bottom: 10px;
	}
	.rightSmall form {
		margin-top: 10px;
	}
	.singlePay .field {
		width: calc(60% - 50px);
		margin-left: 20%;
	}
	.singlePay button {
		width: 48%;
		margin-left: 32%;
	}
	.column {
		width: 100%;
		max-width: 100%;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 700px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 700px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 700px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 700px), 
only screen and (in-resolution: 72dpi) and (max-width: 700px), 
only screen and (min-resolution: 1dppx) and (max-width: 700px), 
only screen and (max-width: 700px) {
	.slideshow{
		width: 100%;
		margin-bottom: 100px;
	}
	#slideOv_holder .ovPos, #slideOv_holder .ovPos .overlay{
		height: 90px;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder a:nth-child(4n + 3) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder a:nth-child(4n + 4) .ovPos .overlay p{
		margin-top: 20px;
	}
	.beforeAfter #slide_holder {
		min-height: 480px;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 600px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 600px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 600px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 600px), 
only screen and (in-resolution: 72dpi) and (max-width: 600px), 
only screen and (min-resolution: 1dppx) and (max-width: 600px), 
only screen and (max-width: 600px) {
	.slideshow{
		width: 100%;
		margin-bottom: 100px;
	}
	#slideOv_holder .ovPos, #slideOv_holder .ovPos .overlay{
		height: 90px;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay p{
		margin-top: 10px;
	}
	#slideOv_holder a:nth-child(4n + 3) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder a:nth-child(4n + 4) .ovPos .overlay p{
		margin-top: 20px;
	}
	#map{
		height: 300px;
	}
	.beforeAfter #slide_holder {
		min-height: 420px;
	}
	.sidebar blockquote{
		width: 50%;
		margin-left: 2.5%
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 550px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 550px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 550px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 550px), 
only screen and (in-resolution: 72dpi) and (max-width: 550px), 
only screen and (min-resolution: 1dppx) and (max-width: 550px), 
only screen and (max-width: 550px){
	.beforeAfter #slide_holder {
		min-height: 390px;
	}
	.acc .hContent:first-child {
		max-height: 650px;
	}
	.acc .hContent:nth-child(2) {
		max-height: 160px;
	}
	.acc .hContent:nth-child(3) {
		max-height: 270px;
	}
	.acc .hContent:nth-child(4) {
		max-height: 220px;
	}
	.acc .hContent:nth-child(5) {
		max-height: 260px;
	}
	.acc .hContent:nth-child(6) {
		max-height: 250px;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 500px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 500px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 500px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 500px), 
only screen and (in-resolution: 72dpi) and (max-width: 500px), 
only screen and (min-resolution: 1dppx) and (max-width: 500px), 
only screen and (max-width: 500px) {
	#content .hServices ul{
		margin-top: 30px;
	}
	#slideOv_holder{
		width: 100%!important;
		height: 110px;
		top: auto;
		bottom: -108px;
	}
	#map{
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		height: 300px;
	}
	.clickArea{
		margin: 0 1%;
		padding: 10px 5% 0;
		width: 88%;
	}
	.beforeAfter #slide_holder {
		min-height: 360px;
	}
	.acc .hContent:first-child {
		max-height: 750px;
	}
	.acc .hContent:nth-child(2) {
		max-height: 170px;
	}
	.acc .hContent:nth-child(3) {
		max-height: 310px;
	}
	.acc .hContent:nth-child(4) {
		max-height: 260px;
	}
	.acc .hContent:nth-child(5) {
		max-height: 305px;
	}
	.acc .hContent:nth-child(6) {
		max-height: 270px;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 420px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 420px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 420px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 420px), 
only screen and (in-resolution: 72dpi) and (max-width: 420px), 
only screen and (min-resolution: 1dppx) and (max-width: 420px), 
only screen and (max-width: 420px) {
	.slideshow{
		width: 100%;
		margin-bottom: 11.2em;
	}
	#slideOv_holder{
		width: 100%!important;
		height: 10em;
		top: auto;
		bottom: -9.7em;
	}
	#slideOv_holder .ovPos, #slideOv_holder .ovPos .overlay{
		height: 10em;
	}
	#slideOv_holder a:nth-child(4n + 2) .ovPos .overlay p{
		margin-top: 20px;
	}
	#slideOv_holder a:nth-child(4n + 3) .ovPos .overlay p, #slideOv_holder a:nth-child(4n + 4) .ovPos .overlay p{
		margin-top: 22px;
	}
	.column{
		width: 100%;
		max-width: 100%;
		margin: 5px 0 20px;
	}
	.hServices{
		width: 100%;
		max-width: 100%;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 20px;
	}
	.beforeAfter #slide_holder {
		min-height: 320px;
	}
	.acc .hContent:first-child {
		max-height: 820px;
	}
	.acc .hContent:nth-child(2) {
		max-height: 195px;
	}
	.acc .hContent:nth-child(3) {
		max-height: 335px;
	}
	.acc .hContent:nth-child(4) {
		max-height: 280px;
	}
	.acc .hContent:nth-child(5) {
		max-height: 350px;
	}
	.acc .hContent:nth-child(6) {
		max-height: 290px;
	}
	.log .field div{
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 380px), 
only screen and (-min-moz-device-pixel-ratio: 1) and (max-width: 380px), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (max-width: 380px), 
only screen and (min-device-pixel-ratio: 1) and (max-width: 380px), 
only screen and (in-resolution: 72dpi) and (max-width: 380px), 
only screen and (min-resolution: 1dppx) and (max-width: 380px), 
only screen and (max-width: 380px) {
	.beforeAfter #slide_holder {
		min-height: 300px;
	}
	.acc .hContent:first-child {
		max-height: 995px;
	}
	.acc .hContent:nth-child(2) {
		max-height: 215px;
	}
	.acc .hContent:nth-child(3) {
		max-height: 380px;
	}
	.acc .hContent:nth-child(4) {
		max-height: 325px;
	}
	.acc .hContent:nth-child(5) {
		max-height: 415px;
	}
	.acc .hContent:nth-child(6) {
		max-height: 335px;
	}
}

@media 
only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 320px) and (max-width: 768px) and (orientation: landscape), 
only screen and (-min-moz-device-pixel-ratio: 1) and (min-width: 320px) and (max-width: 768px) and (orientation: landscape), 
only screen and (-o-min-device-pixel-ratio: 1/1) and (min-width: 320px) and (max-width: 768px) and (orientation: landscape), 
only screen and (min-device-pixel-ratio: 1) and (min-width: 320px) and (max-width: 768px) and (orientation: landscape), 
only screen and (in-resolution: 72dpi) and (min-width: 320px) and (max-width: 768px) and (orientation: landscape), 
only screen and (min-resolution: 1dppx) and (min-width: 320px) and (max-width: 768px) and (orientation: landscape), 
only screen and (min-width: 320px) and (max-width: 768px) and (orientation: landscape) {
	.beforeAfter #slide_holder {
		min-height: 450px;
	}
	.hServices{
		width: 80%;
		margin-bottom: 20px;
	}
	.hServices h2{
		font-size: 30px;
		margin-top: 10px;
	}
	.hServices img{
		margin-top: 10px;
		margin-bottom: 10px;
	}
	#content .hServices ul{
		font-size: 120%;
	}
	.hServices ul li{
		min-height: 70px;
	}
	.customerInv h4 {
		max-width: 30%;
	}
	#logOut{
		align-self: flex-end;
	}
	#content .hServices ul{
		font-size: 90%;
		margin-bottom: 10px;
	}
	#whyChoose{
		width: 40%;
	}
	#vidSpacing {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}
	.leftLarge{
		width: 100%;
		margin-bottom: 10px;
	}
	.rightSmall{
		width: 40%;
		margin-bottom: 10px;
	}
	.rightSmall form {
		margin-top: 10px;
	}
	.singlePay .field {
		width: calc(60% - 50px);
		margin-left: 20%;
	}
	.singlePay button {
		width: 48%;
		margin-left: 32%;
	}
	.footer-left {
		float:left;
		text-align:left;
		width: 39%;
		margin-left: 1%;
	}
	.footer-right {
		float:right;
		text-align:right;
		width:59%;
		margin-right: 1%;
	}
	.footerInfo .fNav {
		align-self: flex-start;
		margin: 0;
		text-align: left;
		width: 38%;
		margin-left: 1%;
	}
	.footerInfo .fNav li{
		align-self: flex-start;
		margin: 0;
		text-align: left;
		width: 38%;
		margin-left: 1%;
	}
	.footerInfo .fButtons {
		width: 20%;
	}
	.footerInfo .fAddress {
		align-self: flex-start;
		margin: 0;
		text-align: right;
		width: 38%;
		margin-right: 1%;
	}
}
/* End of Mitch Extra Styling */

section.blogPost img {
    width: 100%;
}

section.blog ol.posts {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 40px;
}

section.blog ol.posts li {
    background-color: initial;
    width: 500px;
}

section.blog ol.posts li h2 {
    font-size: 18px;
    color: #FFFFFF;
}

section.blog ol.posts li p.date {
    text-align: center;
    margin-top: 5px;
}

section.blog ol.posts li div.content {
    display: flex;
}

section.blog ol.posts li div.content img {
    width: 200px;
}

section.blog ol.posts li div.content div.right {
    margin-left: 10px;
}
