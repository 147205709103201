@font-face {
    font-family: fjallaOne;
    src: url('../fonts/fjalla-one/regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: quattrocentoSans;
    src: url('../fonts/quattrocento-sans/regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: quattrocentoSansItalic;
    src: url('../fonts/quattrocento-sans/italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: quattrocentoSansBold;
    src: url('../fonts/quattrocento-sans/bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: quattrocentoSansBoldItalic;
    src: url('../fonts/quattrocento-sans/bold-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

